import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { sortArray } from 'magmel';

// components
import { ShootPage, SectionHeader } from 'src/components/Photography';
import { Navbar, Gallery, Banner, Footer } from 'src/components/common';

const EventPage = () => {
  return (
    <>
      <ShootPage
        style={{
          marginTop: '100px',
        }}
      >
        <Navbar dark />
        <div className="container-lg">
          <SectionHeader sectionName="essie spice cooking class" />
        </div>

        <Grid />
        <a class="ca3-scroll-down-link ca3-scroll-down-arrow" />

        {/* <div className="write-up container-lg">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus ipsa repudiandae
            nemo cumque facilis minima, numquam, molestiae perferendis laboriosam eaque vel non
            quaerat tempore iure vitae. Quidem autem sit ducimus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, cum. Debitis,
            blanditiis voluptatem placeat unde fugiat laudantium cum ratione velit, ab enim
            repudiandae. Esse consequatur, reiciendis animi delectus at magni!
          </p>
        </div> */}
      </ShootPage>

      <Banner />
      <Footer />
    </>
  );
};

const Grid = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(
            filter: { relativePath: { regex: "/photography/events/esi-spice-cooking-class/" } }
          ) {
            nodes {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                  originalImg
                }
              }
            }
          }
        }
      `}
      render={({ allFile }) => {
        const sortedArray = sortArray({
          array: allFile.nodes,
          order: 'asce',
          key: 'name',
        });

        return (
          <div>
            <Gallery
              itemsPerRow={[2, 3]}
              classNames="full-width"
              images={sortedArray.map((file, index) => {
                return {
                  id: String(index),
                  ...file.childImageSharp.fluid,
                  caption: 'gallery-image',
                };
              })}
            />
          </div>
        );
      }}
    />
  );
};

export default EventPage;
